.cs-message:only-child {
  margin: 0 0 0 0 ;
}

.cs-message__content {
  padding: 16px;
}

.cs-message--outgoing .cs-message__content {
  background-color: var(--mui-palette-primary-dark);
  color: var(--mui-palette-primary-contrastText);

}

.cs-message--incoming .cs-message__content {
  background-color: var(--mui-palette-text-_states-hover);
  color: var(--mui-palette-text-primary);
}

.cs-message.cs-message--outgoing.cs-message--single .cs-message__content {
  border-radius: .8em .8em 0 .8em;
}

.cs-message.cs-message--incoming.cs-message--single .cs-message__content {
  border-radius: .8em 0.8em .8em 0em;
}

.cs-message-input__content-editor-wrapper {
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  /* height: calc(100%); */
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor {
  background-color: #fbfbfb;
}

.cs-message-input--disabled .cs-message-input__content-editor-wrapper {
  background-color: #e7e7e7;
}

.cs-message-input__tools button.cs-button.cs-button--send:disabled {
  opacity: 1;
  color: #d9d9d9;
}

.cs-message-separator {
  color: #50555c;
}

.cs-message-input__tools button.cs-button.cs-button--send {
  color: #3da7e7;
}
.cs-message-separator::before,
.cs-message-separator::after,
.cs-typing-indicator__indicator > .cs-typing-indicator__dot {
  background-color: #a8a8a8;
}
.cs-message-separator {
  margin: 10px 0;
}
/* .cs-message-list
  > .scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y {
  padding-bottom: 18px;
} */

/* .cs-message-list__scroll-wrapper {
  padding-left: 20px;
  padding-right: 20px;
} */

.cs-message-list__typing-indicator-container > .cs-typing-indicator {
  padding: 0 1em;
}

.custom-message-avatar img {
  object-fit: cover;
}

.custom-message-avatar:hover>img{
  filter: none;
}
